import { useRouter } from "next/router";
import { useEffect } from "react";

export const GTM_ID = `GTM-${process.env.NEXT_PUBLIC_GTM_CONTAINER_ID}`;

export const pageView = (url) => {
  addLayer({
    event: "pageview",
    page: url,
  });
};

export const customClick = (event, clickId) => {
  event.target.id = clickId;
  addLayer({
    event: "gtm." + event.type,
    ["gtm.element"]: event.target,
    ["gtm.elementClasses"]: event.target.className || "",
    ["gtm.elementId"]: event.target.id,
    ["gtm.elementTarget"]: event.target.target || "",
    ["gtm.elementUrl"]: event.target.href || event.target.action || "",
  });
};

export const useGoogleTagManager = () => {
  const router = useRouter();
  useEffect(() => {
    router.events.on("routeChangeComplete", pageView);
    return () => {
      router.events.off("routeChangeComplete", pageView);
    };
  }, [router.events]);
};

export const dispatchArticleViewed = (
  articleSlug?: string,
  categorySlug?: string,
) => {
  addLayer({
    event: "Article Viewed",
    article: articleSlug,
    article_category: categorySlug,
  });
};

export const clearArticleDataLayer = () => {
  addLayer({
    article: undefined,
    article_category: undefined,
  });
};

export const dispatchModalViewed = (modalTitle?: string) => {
  addLayer({
    event: "Modal Viewed",
    title: modalTitle || "Website Modal",
  });
};

export const dispatchNylLandingPage = () => {
  addLayer({
    event: "NYL Experiment",
    variant: "Landing Page",
  });
};

export const clearModalDataLayer = () => {
  addLayer({ title: undefined });
};

function addLayer(layer) {
  (window as any)?.dataLayer?.push(layer);
}

export const setTestUser = () => {
  addLayer({ isTestUser: true });
};

export const clearTestUser = () => {
  addLayer({ isTestUser: undefined });
};

export const reportSignUpToNewsletter = () => {
  addLayer({
    event: "form_submission",
    formType: "newsletter submission",
  });
};

export const reportBookDemo = ({ vertical }: { vertical: string }) => {
  addLayer({
    event: "form_submission",
    formType: "book a demo",
    vertical,
  });
};

export const reportWebinar = ({ webinar }: { webinar: string }) => {
  addLayer({
    event: "form_submission",
    formType: "webinar",
    webinar,
  });
};
