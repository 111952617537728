import { Asset } from "contentful";
import { DefaultSeoProps } from "next-seo";

export const DEFAULT_SEO: DefaultSeoProps = {
  title: "empathy.com",
  titleTemplate: "%s | Empathy",
  defaultTitle: "Empathy",
  description:
    "Coping with the loss of loved ones is not easy. We will guide you about what to expect following a loss and the tools to get you through each step.",
  openGraph: {
    type: "website",
    locale: "en",
    description:
      "Coping with the loss of loved ones is not easy. We will guide you about what to expect following a loss and the tools to get you through each step. ",
    site_name: "Empathy",
    images: [
      {
        url: "https://images.ctfassets.net/yuio4g5azqaw/2Bzj5VbAgiDQEJAlC22jA0/9383651d261c7f1c731cf3e334603150/og_share_image.png",
        width: 1200,
        height: 630,
        alt: "Empathy",
      },
    ],
  },
  twitter: {
    site: "@empathy",
  },
};

export function metaImageFromAsset(asset: Asset | undefined) {
  if (!asset) return undefined;
  const { title, file } = asset.fields;
  return {
    url: file.url.replace("//", "https://") || "",
    width: file.details.image.width,
    height: file.details.image.height,
    alt: title,
  };
}
