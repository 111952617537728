import { Analytics } from "@vercel/analytics/react";
import "../styles/globals.css";
import { DefaultSeo } from "next-seo";
import Head from "next/head";
import { Router } from "next/router";
import Script from "next/script";
import NProgress from "nprogress";
import { useEffect } from "react";
import { FB_PIXEL_ID, useFacebookPixel } from "../lib/fbpixel";
import { GTM_ID, useGoogleTagManager } from "../lib/googleTagManager";
import { setLastTouchAttribution } from "../lib/getAttributionParams";
import { DEFAULT_SEO } from "../lib/seo";
import { useCookiesConsent } from "../lib/useCookiesConsent";
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());
export default function MyApp({
  Component,
  pageProps
}) {
  useEffect(() => {
    document.documentElement.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
  }, []);
  useEffect(() => {
    setLastTouchAttribution();
  }, []);
  const {
    hasCookieConsent
  } = useCookiesConsent();
  useFacebookPixel();
  useGoogleTagManager();
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
        <meta name="viewport" content="width=device-width, initial-scale=1" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta key="google-site-verification" name="google-site-verification" content={process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION} data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
      </Head>
      <DefaultSeo {...DEFAULT_SEO} data-sentry-element="DefaultSeo" data-sentry-source-file="_app.tsx" />

      {hasCookieConsent && <Script async strategy="lazyOnload" src={`https://www.googletagmanager.com/gtm.js?id=${GTM_ID}`} />}
      <Script dangerouslySetInnerHTML={{
      __html: `(function(w,d,l){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'})})(window,document,'dataLayer');`
    }} data-sentry-element="Script" data-sentry-source-file="_app.tsx" />
      <Script strategy="afterInteractive" dangerouslySetInnerHTML={{
      __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', ${FB_PIXEL_ID});`
    }} data-sentry-element="Script" data-sentry-source-file="_app.tsx" />
      <Component {...pageProps} class="flex flex-1" data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
      <Analytics data-sentry-element="Analytics" data-sentry-source-file="_app.tsx" />
      {/* EMPTY Script tag at the end is there to fix a weird chrome bug that triggers animations */}
      <script>{` `}</script>
    </>;
}