// Based on https://github.com/vercel/next.js/tree/dc4beade676b6c0310320c1a15de9213105c13cb/examples/with-facebook-pixel
import { useEffect } from "react";
import { useRouter } from "next/router";
import * as fbq from ".";

const handleRouteChange = () => {
  fbq.pageview();
};

export const useFacebookPixel = () => {
  const router = useRouter();

  useEffect(() => {
    // This pageview only trigger first time (it is important for Pixel to have real information)
    fbq.pageview();

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
};
