import { useEffect, useMemo, useState } from "react";
import Cookies from "js-cookie";
import { isUserFromGdprCountry } from "./isUserFromGdprCountry";

export const COOKIE_CONCENT_COOKIE_NAME = "CookieConsent";

export function useCookiesConsent() {
  const [isGdprCountry, setIsGdprCountry] = useState(undefined);

  useEffect(() => void isUserFromGdprCountry().then(setIsGdprCountry), []);

  const hasCookieConsent = useMemo(() => {
    const isGdprReady = isGdprCountry !== undefined;

    if (!isGdprReady) {
      return false;
    }

    return !isGdprCountry || Cookies.get(COOKIE_CONCENT_COOKIE_NAME) === "true";
  }, [isGdprCountry]);

  const userId = useMemo((): number => {
    if (Cookies.get("expuid")) return Number(Cookies.get("expuid"));

    const userId = Math.random() * +new Date();

    if (hasCookieConsent) {
      Cookies.set("expuid", String(userId));
    }

    return userId;
  }, [hasCookieConsent]);

  return {
    hasCookieConsent,
    isGdprCountry,
    userId,
  };
}
