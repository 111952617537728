let response: boolean = undefined;

const GDPR_COUNTRIES = [
  "AT", //Austria
  "BE", //Belgium
  "BG", //Bulgaria
  "HR", //Croatia
  "CY", //Cyprus
  "CZ", //Czech Republic
  "DK", //Denmark
  "EE", //Estonia
  "FI", //Finland
  "FR", //France
  "DE", //Germany
  "GR", //Greece
  "HU", //Hungary
  "IE", //Ireland //Northern Ireland
  "IT", //Italy
  "LV", //Latvia
  "LT", //Lithuania
  "LU", //Luxembourg
  "MT", //Malta
  "NL", //Netherlands
  "PL", //Poland
  "PT", //Portugal, Azores, Madeira
  "RO", //Romania
  "SK", //Slovakia
  "SI", //Slovenia
  "ES", //Spain
  "SW", //Sweden
  "JE", //Channel Isles
  "GB", //England, United Kingdom, Scotland, Wales
  "IS", //Iceland
  "LI", //Lichtenstein
  "NO", //Norway
  "IC", //Canary Islands
  "GP", //Guadeloupe
  "GF", //French Guiana
  "MQ", //Martinique
  "YT", //Mayotte
  "RE", //Reunion
  "MF", //Saint Martin
];

export async function isUserFromGdprCountry(): Promise<boolean> {
  if (response !== undefined) {
    return response;
  }

  return fetch("https://api.country.is/")
    .then((res) => res.json() as Promise<{ country: string }>)
    .then(({ country }) => {
      return (response = GDPR_COUNTRIES.some((x) => x === country));
    })
    .catch(() => (response = true));
}
