import { getSalesforceUtmParams } from "../components/Harmony/BookDemo/getSalesforceUtmParams";

const LAST_TOUCH_ATTRIBUTION_KEY = "lastTouchAttribution";
const DEFAULT_ATTRIBUTION_PARAMS = { lead_source: "Website" };

export function setLastTouchAttribution() {
  const newSalesforceUtmParams = getSalesforceUtmParams();
  if (!hasSalesforceAttributionParams(newSalesforceUtmParams)) {
    return;
  }

  const oldUTMParams = getExistingAttributionParams();
  const landingPageParam = getLandingPageAttribution();

  setAttributionParams({
    ...DEFAULT_ATTRIBUTION_PARAMS,
    ...oldUTMParams,
    ...newSalesforceUtmParams,
    ...landingPageParam,
  });
}

export const getExistingAttributionParams = () =>
  JSON.parse(localStorage.getItem(LAST_TOUCH_ATTRIBUTION_KEY));

const setAttributionParams = (params: any) =>
  localStorage.setItem(LAST_TOUCH_ATTRIBUTION_KEY, JSON.stringify(params));

const hasSalesforceAttributionParams = (salesforceUtmParams) =>
  Object.keys(salesforceUtmParams).length > 0;

const getLandingPageAttribution = () => {
  let landingPageParam = {};
  if (typeof window !== "undefined") {
    landingPageParam = {
      Website_Landing_Page__c: window.location.host + window.location.pathname,
    };
  }

  return landingPageParam;
};
