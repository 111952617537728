import { compactObject } from "../../../lib/util";
export const getSalesforceUtmParams = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return compactObject({
    utm_campaign__c: urlParams.get("utm_campaign"),
    utm_content__c: urlParams.get("utm_content"),
    utm_medium__c: urlParams.get("utm_medium"),
    lead_source: urlParams.get("utm_source"),
    utm_term__c: urlParams.get("utm_term"),
    GCLID__c: urlParams.get("gclid")
  });
};