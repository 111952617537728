// Based on https://github.com/vercel/next.js/blob/dc4beade676b6c0310320c1a15de9213105c13cb/examples/with-facebook-pixel/lib/fpixel.js

// https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
interface LeadEventOptions {
  content_category?: string;
  content_name?: string;
  currency?: string;
  value?: number;
}

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

export { useFacebookPixel } from "./useFacebookPixel";

const getFBQFunction = () => {
  const fbq = (window as any).fbq;
  if (!fbq) {
    console.warn(`Missing window.fbq. Pixel ID: ${FB_PIXEL_ID}`);
    return null;
  }

  return fbq;
};

export const pageview = () => {
  const fbq = getFBQFunction();

  fbq?.("track", "PageView");
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: string, options = {}) => {
  const fbq = getFBQFunction();

  fbq?.("track", name, options);
};

export const leadEvent = (options: LeadEventOptions = {}) => {
  event("Lead", options);
};

export const customEvent = (name: string, options = {}) => {
  const fbq = getFBQFunction();

  fbq?.("trackCustom", name, options);
};
