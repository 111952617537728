import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import isEmpty from "lodash/isEmpty";
import omitBy from "lodash/omitBy";

// Vercel specific
export const getAbsoluteURL = (path) => {
  const baseURL = process.env.VERCEL_URL
    ? `https://${process.env.VERCEL_URL}`
    : "http://localhost:3001";
  return baseURL + path;
};

export const getCompanionBaseURL = () => {
  return `${process.env.NEXT_PUBLIC_WEB_COMPANION_BASE_URL}`;
};

export const normalizeButtonId = (btnId: string) => {
  const searchRegExp = /\s/g;
  const replaceWith = "_";

  return btnId.replace(searchRegExp, replaceWith).toLocaleLowerCase();
};

export const queryParams = (params: any): string => {
  const queryParams = omitBy(params, isEmpty);
  if (isEmpty(queryParams)) return "";
  const urlParams = new URLSearchParams(queryParams);
  return `?${urlParams}`;
};

export const compactObject = <T extends object>(obj: T) => {
  return pickBy(obj, identity);
};

export const toKebabCase = (str: string) => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => "-" + chr)
    .trim();
};

export const wait = (ms = 1000) =>
  new Promise((resolve) => setTimeout(resolve, ms));
